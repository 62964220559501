.contactWrapper {
  background-color: #2e294e;
  position: relative;
}
.clipPathVectorContactSection {
  width: 100vw;
  border-top: 120px solid transparent;
  border-left: 100vw solid #2e294e;
  position: absolute;
  top: -119px;
}

.textSectionContact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.titleContactSection {
  padding: 0 0 100px;
  text-align: center;
  font-size: 50px;
  font-weight: bold;
  color: white;
}

.textBehindTitleContactSection {
  color: white;
  font-size: 20px;
  text-align: center;
  padding: 30px;
  display: inline-block;
  background-color: #2e294e;
  width: max-content;
  margin: 0 0 60px;
  border-radius: 22px;
  border: 1px solid white;
}

.iconsSocialMediaWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 80px;
}

.iconSocialMedia {
  color: white;
  font-size: 31px;
  margin: 0 16px;
  padding: 9px;
  border-radius: 22px;
  border: 1px solid white;
}

.iconSocialMedia:hover {
  color: #2e294e;
  background-color: white;
  border: 1px solid #a35752;
}

.hyperLinkToSocialMedia {
  text-decoration: none;
}

.textBehindTitleContactSectionSmallScreen {
  display: none;
}

@media (max-width: 800px) {
  .textBehindTitleContactSection {
    font-size: 17px;
    padding: 20px;
    margin: 0 0 52px;
  }
  .iconsSocialMediaWrapper {
    justify-content: center;
  }
  .iconSocialMedia {
    font-size: 21px;
    margin: 0 13px;
  }
}

@media (max-width: 535px) {
  .textBehindTitleContactSection {
    font-size: 15px;
    padding: 11px;
  }
  .iconSocialMedia {
    font-size: 18px;
    margin: 0px 9px;
  }
  .titleContactSection {
    padding: 0 0 70px;
  }
}

@media (max-width: 375px) {
  .textBehindTitleContactSection {
    display: none;
  }
  .textBehindTitleContactSectionSmallScreen {
    color: white;
    font-size: 19px;
    text-align: center;
    padding: 11px;
    display: flex;
    justify-content: center;
    background-color: #2e294e;
    width: max-content;
    margin: 0 60px 60px;
    border-radius: 22px;
    border: 1px solid white;
  }
  .titleContactSection {
    font-size: 40px;
  }
}

@media (max-width: 360px) {
  .textBehindTitleContactSectionSmallScreen {
    font-size: 14px;
    padding: 8px;
    border-radius: 0;
    border: none;
  }
}
