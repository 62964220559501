.myOfferWrapper {
  background-color: #2e294e;
  position: relative;
  padding-bottom: 119px;
}

.clipPathVectorMyOfferSection1 {
  /*
  con clip-path:
  width: 100%;
  height: 150px;
  -webkit-clip-path: polygon(0% 0%, 100% 25%, 100% 100%, 0% 100%);
  clip-path: polygon(0% -180%, 104% 7%, 77% 32%, -6% 100%);
  background-color: #dadada;
  */
  width: 100vw;
  border-left: 100vw solid transparent;
  border-bottom: 120px solid #2e294e;
  position: absolute;
  top: -119px;
}

.wrapperTitle {
  padding: 0px 0 100px;
  text-align: center;
  font-size: 50px;
  font-weight: bold;
  color: white;
}

.wrapperCirclesUXUI {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0 5%;
  color: white;
  padding-bottom: 80px;
}

.circleWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vectorIcon {
  height: 50px;
  width: auto;
  padding: 40px;
  border-radius: 49px;
  color: #2e294e;
  background-color: white;
  border: 1px solid #2e294e;
}

.titleVectorIcon {
  padding-top: 15px;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 1.2px;
  text-align: center;
}

.textVectorIcon {
  width: 63%;
  padding-top: 6px;
  text-align: center;
  letter-spacing: 1.1px;
}

.ProgrammingSkillsWords {
  display: flex;
  justify-content: center;
  text-align: center;
  color: white;
}

@media (max-width: 800px) {
  .circleWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20%;
  }
  .wrapperTitle {
    padding: 0px 0 20px;
  }

  .wrapperCirclesUXUI {
    flex-direction: column;
  }
  .textVectorIcon {
    width: 96%;
  }
  .titleVectorIcon {
    font-size: 24px;
    letter-spacing: 1.1px;
  }
  .vectorIcon {
    height: 45px;
    padding: 28px;
    border-radius: 39px;
  }
}

@media (max-width: 425px) {
  .wrapperCirclesUXUI {
    flex-direction: column;
  }
  .circleWrapper {
    padding-bottom: 60px;
  }
  .titleVectorIcon {
    font-size: 26px;
  }
  .wrapperTitle {
    padding-top: 20px;
    font-size: 40px;
  }
}
