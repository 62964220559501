.floatingButtonHide,
.floatingButtonShow {
  position: fixed;
  bottom: 3%;
  right: 1%;
  font-size: 40px;
  background-color: #a35752;
  color: white;
  border-radius: 22px;
  box-shadow: 2px 1px 14px dimgrey;
  cursor: pointer;
  border: 1px solid white;
  padding: 5px;
  transform: scale(0.6);
  transition: opacity 1s, box-shadow 1s, transform 0.3s ease-in-out;
}

.floatingButtonHide {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease-in-out;
}

.floatingButtonShow {
  transition: opacity 1s ease-in-out;
  opacity: 0.5;
}

.floatingButtonShow:hover {
  opacity: 1;
  transform: scale(1);
}
