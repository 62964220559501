.wrapperPresentationSection {
  background-color: #dadada;
  border-top: 100% solid #e37971;
  position: relative;
  padding-bottom: 119px;
}
.wrapperWhoIam {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  padding: 100px 5% 170px;
}

.clipPathVectorPresentationSection {
  /* width: 100%;
  height: 100px;
  -webkit-clip-path: polygon(0 -201%, 104% 7%, 100% 100%, 0% 0);
  clip-path: polygon(0 -201%, 104% 7%, 100% 100%, 0% 0);
  background-color: #e37971;*/
  width: 100vw;
  border-top: 120px solid transparent;
  border-left: 100vw solid #dadada;
  position: absolute;
  top: -119px;
}

.containerPhotoPresentation {
  width: 45%;
  display: flex;
  justify-content: center;
}

.photoGabiPresentation {
  height: 350px;
  width: auto;
  border-radius: 70px;
}

.textWrapperPresentation {
  width: 45%;
  color: #2e294e;
}
.textTitlePresentation {
  text-align: center;
  font-size: 50px;
  font-weight: bold;
  color: #2e294e;
}

.textParagraphPresentation {
  line-height: 1.5;
  font-weight: 600;
  letter-spacing: 0.6px;
  font-size: 18px;
}

.aboutMeWords {
  display: flex;
  justify-content: center;
  text-align: center;
}

@media (max-width: 1024px) {
  .photoGabiPresentation {
    height: 320px;
  }
  .textParagraphPresentation {
    font-size: 16px;
  }
}

@media (max-width: 800px) {
  .photoGabiPresentation {
    height: 250px;
  }
  .textParagraphPresentation {
    font-size: 14px;
  }
  .wrapperWhoIam {
    justify-content: space-evenly;
    padding: 100px 7% 50px;
  }
}

@media (max-width: 600px) {
  .photoGabiPresentation {
    height: 250px;
  }

  .textWrapperPresentation {
    width: 90%;
  }
  .textParagraphPresentation {
    font-size: 17px;
    padding: 40px 5% 0;
    width: 90%;
    text-align: center;
  }
  .wrapperWhoIam {
    padding: 100px 5% 50px;
    flex-direction: column;
    width: 90%;
  }
}

@media (max-width: 320px) {
  .textTitlePresentation {
    font-size: 40px;
  }
}
